<template>
  <!-- actions START -->
  <div
    :class="[
      'cart-actions',
      { 'no-products' : products.items && products.items.length === 0 }
    ]"
    v-if="products.items"
  >
    <!-- cart:actions START -->
    <div class="actions">
      <!-- cart:actions:scan START -->
      <router-link
        :to="''"
        class="btn-default btn-scan-product"
        v-on:click.native="!eanActive ? askUser() : askUser(Number(productEAN))"
        v-if="!eanActive"
      >
        <span
          v-if="products.items.length === 0"
          v-html="`+ ${$t('cart.scanfirstproduct')}`"
        ></span>
        <span
          v-else
          v-html="`+ ${$t('cart.scananotherproduct')}`"
        ></span>
        <i class="icon-add-product" v-if="products.items.length === 0"></i>
      </router-link>

      <router-link
        :to="''"
        class="btn-default btn-scan-product"
        v-on:click.native="!eanActive ? askUser() : askUser(Number(productEAN))"
        v-else
      >
        <span
          v-if="products.items.length === 0"
          v-html="`+ ${$t('cart.addfirstproduct')}`"
        ></span>
        <span
          v-else
          v-html="`+ ${$t('cart.addanotherproduct')}`"
        ></span>
        <i class="icon-add-product" v-if="products.items.length === 0"></i>
      </router-link>
      <!-- cart:actions:scan END -->

    </div>
    <!-- cart:actions END -->

    <!-- cart:actions:checkout START -->
    <transition name="fade-up-slow" mode="out-in">
      <div
        class="checkout"
        :key="'checkout'"
        v-if="products.items && products.items.length !== 0"
      >

        <!-- cart:actions:checkout:total START -->
        <div class="total">
          <div class="total-text" v-html="$t('cart.total')"></div>
          <transition name="scale" mode="out-in">
            <div :key="total" class="total-number scale-animation">
              <span v-html="`${products.currencySymbol} ${priceFormat(total)}`"></span>
            </div>
          </transition>
        </div>
        <!-- cart:actions:checkout:total END -->

        <!-- cart:actions:checkout:to-order START -->
        <transition name="opacity" mode="out-in">
          <div class="to-order">
            <button
              class="btn-default btn-to-order"
              :disabled="products.items && products.items.length === 0"
              v-on:click="checkoutCart(toOrder = true)"
              v-html="`${$t('cart.paynow')}`"
            ></button>
          </div>
        </transition>
        <!-- cart:actions:checkout:to-order END -->

      </div>
    </transition>
    <!-- cart:actions:checkout END -->

  </div>
  <!-- actions END -->
</template>

<script>
export default {
  name: 'actions',
  props: {
    products: {
      type: Object,
    },

    total: {
      type: Number,
    },

    productEAN: {
      type: String,
    },

    eanActive: {
      type: Boolean,
    },

    checkoutCart: {
      type: Function,
    },

    askUser: {
      type: Function,
    },
  },
  data() {
    return {
      toOrder: false,
    };
  },
  methods: {
    priceFormat(price) {
      let activeLang = 'de-DE';
      if (localStorage.getItem('lang') === 'en_US') {
        activeLang = 'en-US';
      }
      return new Intl.NumberFormat(
        activeLang,
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        },
      ).format(price);
    },
  },
  mounted() {
    this.checkoutCart(this.toOrder);
  },
};
</script>
